    body {
        margin: 0;
        font-family: "Roboto", sans-serif;
        -webkit-font-smoothing: "Roboto", sans-serif;
        -moz-osx-font-smoothing: "Roboto", sans-serif;
        font-style: normal;
        overflow: scroll;
        overflow-x: hidden;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
    }

    :root {
        --primary: #5e3bee;
        --heading-color: #282938;
        --bg-shade: #f5fcff;
        --github: #e62872;
        --darkblue: #1c1e53;
        --black: #000000;
        --white: #ffffff;
    }

    .btn {
        font-family: "Roboto";
        display: inline-block;
        padding: 14px 32px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        transition: background-color 0.5s;
        -webkit-transition: background-color 0.5s;
        -moz-transition: background-color 0.5s;
        -ms-transition: background-color 0.5s;
        -o-transition: background-color 0.5s;
    }

    .btn-outline-primary {
        color: var(--primary);
        background-color: var(--white);
        border: 1px solid var(--primary);
    }

    .btn-outline-primary:hover {
        color: var(--white);
        background-color: var(--primary);
    }

    .btn-primary {
        color: var(--white);
        background-color: var(--primary);
        border: 1px solid var(--primary);
    }

    .btn-primary:hover {
        color: var(--primary);
        background-color: var(--white);
    }

    .btn-github {
        color: var(--white);
        background-color: var(--github);
        border: var(--github);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
        gap: 16px;
    }

    .btn-github:hover {
        color: var(--github);
        background-color: var(--white);
    }

    /* Heading 1 */
    h1 {
        font-size: 56px;
        font-weight: 700;
        line-height: 67px;
    }

    /* Heading 2 */
    h2 {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
    }

    /* Heading 3 */
    h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
    }

    /* Body 1 */
    .text-lg {
        color: var(--darkblue);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
    }
    .text-md {
        color: var(--darkblue);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }
    .text-sm {
        color: var(--black);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }

    /* Section Title */

    .section--title {
        font-size: 21px;
        text-align: center;
        font-style: normal;
        display:  flex;
        align-items: flex-start;
        font-weight: 600;
        line-height: 32px;
        color: var(--heading-color);
    }

    .sub--title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--heading-color);
    }
    /* Section Title Ends */

    /* Navbar Style Start */

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 85.333px;
        background: var(--white);
        box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 750;
    }

    .navbar--items>ul {
        list-style: none;
        display: flex;
        align-items: flex-start;
        gap: 42.667px;
        text-decoration: none;
    }

    .navbar--items ul>li>a {
        text-decoration: none;
    }

    /* Navbar Content */
    .navbar--content {
        color: var(--darkblue);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        cursor: pointer;
    }

    .navbar--active-content {
        color: var(--primary);
    }

    /* Navbar Styler Ends */

    /* Hero Section Style */

    .hero--section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 133.333px 85.333px 133.333px;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
        background-color: var(--bg-shade);
    }

    .hero--section--content-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }

    .hero--section--content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 21.333px;
    }


    .hero--section--content--box>button {
        margin-top: 21.333px;
    }

    .hero--section--title {
        color: var(--heading-color);
        font-size: 74.667px;
        font-weight: 700;
        line-height: 90px;
        align-self: stretch;
    }

    .hero--section--title--color {
        color: var(--primary);
    }

    .hero--section-description {
        color: var(--darkblue);
        font-family: 'Times New Roman', Times, serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
    }

    .hero--section--img {
        display: flex;
    }

    .hero--section--img>img {
        width: 100%;
        height: 100%;
    }
    /* Hero Section Style Ends */

    /* Favorites Section Style Starts */
    .favorites {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 50px;
        padding-top:  85.33px;
        max-width: 90%;
        max-height: 80%;
        height: 80%;
        justify-content: space-between;
    }
    .favorites-heading--first{
        font-size: 64px;
        font-weight: 700;
        line-height: 77px;
    }

    .favorites-heading {
        color: var(--darkblue);
        font-size: 30px;
        font-weight: 700;
        font-family: 'Courier New', Courier, monospace;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75%;
        /* padding: 10px; */
        margin-bottom: 20px;
        margin: 0;
        background-color: #f5fcff;
        box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
    }

    .favorites>img {
        list-style: none;
        display: flex;
        flex-direction: row;
       /*  padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px; */
        align-items: center;
        margin-left: auto;
        gap: 21.3px;
        max-width: 50%;
        max-height: 75%;
        height: 75%;
        justify-content: space-between;
    }

    /* Favorites Section Style Ends */

    /* Skills Section Style */
    .skills--section {
        display: flex;
        padding: 149.33px 85.33px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 106.667px;
    }

    .skills--section--heading {
        color: var(--heading-color);
        font-size: 64px;
        font-weight: 700;
        line-height: 77px;
    }

    .skills--section--container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 42.6px;
        grid-template-columns: repeat(4, 1fr);
    }

    .skills--section--description {
        color: var(--darkblue);
        background-color: #f5fcff;
    }
    .skills--section--description >p {
        color: var(--primary);
        font-family: Arial, Helvetica, sans-serif;
        font-size: 21.3px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        border-radius: 10.6px;
        gap: 40px;  
        padding: 50px;
        background: #f5fcff;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    }
    .skills--section--card {
        display: flex;
        padding: 32px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
        border-radius: 10.6px;
        background: var(--bg-shade);
        min-height: 250px;
    }

    .skills--section--card:hover {
        border-bottom: 4px solid var(--primary);
    }

    .skills--section--card:hover .skills--section--description {
        color: var(--darkblue);
    }

    .skills--section--img {
        display: flex;
        padding: 13.3px;
        justify-content: center;
        align-items: center;
        gap: 13.3px;
        border-radius: 10.6px;
        background: #fff;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    .skills--section--card--content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
    }

    .skills--section--title {
        color: var(--heading-color);
        font-size: 32px;
        font-weight: 700;
        line-height: 45px;
    }

    .skills--section--description {
        color: var(--black);
        font-size: 21.3px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    /* Skills Section Style Ends */

    /* About Me Section Style Starts */
    .about--section {
        display: grid;
        padding: 133.3px 85.3px;
        align-items: center;
        flex-direction: row;
        gap: 114.6px;
        grid-template-columns: repeat(2, 1fr);
    }

    .about--section--img>img {
        width: 100%;
        height: 100%;
    }
    .text-box {
        margin-top: 20px;
        padding: 10px;
    }
    .meetup-description {
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        color: var(--black);
        background-color: #f5fcff;
        opacity: 0.9;
        padding: 10px;
        font-weight: 700;
        margin-bottom: 20px;
    }   
    .text-box p {
        margin-top: 10px;
        color: #555;
    }
    .about--section--alt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 21.3px;
        line-height: 40px;
        max-width: 150%; 
        height: auto;  
    }
    .about--section--alt >h1 {
        font-size: 50px;
        
    }
    .about--section--ul {
        display: flex;
        flex-direction: row;
        align-items:  normal;
        gap: 21.3px;
        line-height: 40px;
        max-width: 100%;
        height: auto;  
        background: #fff;
        overflow-x: auto;
    }
    .nolan-link {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
    }

    .nolan-link:hover {
        color: #007bff;
    }
    .about--section--ul >img {
        list-style: none;
        display: grid;
        flex-direction: row;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-items: center;
        gap: 21.3px;
        max-width: 100%;  
        height: 100%; 
        justify-content: space-between;    
    }

    .about--section--heading {
        color: var(--github);
        font-size: 50px;
        font-weight: 700;
        padding-left: 43px;
    }


    /* About Me Section Style Ends */

    /* Footer Section Starts */
    .footer--container {
        display: flex;
        padding: 106.667px 85.333px;
        flex-direction: column;
        background: #eefaff;
        align-items: center;
        justify-content: space-evenly;
        
        }

    .footer--social--icon>ul {
        list-style: none;
        display: flex  ;
        height: 2%;
        width: 100%;
        max-width: 100%;
        justify-content: space-evenly   ;
    /*     gap: 5px;
        flex-shrink: 5; */
    }

    .divider {
        margin: 106px 0 42.67px;
        height: 1.333px;
        width: 100%;
        background: rgba(40, 41, 56, 0.55);
    }

    .footer--content--container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }

    .footer--content {
        color: var(--black);
        font-size: 18.667px;
        font-weight: 400;
        line-height: 28px;
    }

    /* Footer Section Ends */

    /* Contact Me Section Starts */
    .contact--section {
        display: flex;
        margin-top: 50px;
        padding: 106.667px 85.333px;
        flex-direction: column;
        align-items: flex-start;
        background: #97dffe;
        align-items: center;
        align-self: stretch;
    }

    .contact--me--small {
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        color: #e62872;
        background-color: #f5fcff;
        align-items: center;
        gap: 21.333px;
        padding: 50px;
        line-height: 40px;
        height: auto;
        justify-content: space-between;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact--label {
        color: var(--github);
        font-size: 21.333px;
        font-weight: 700;
        margin: 10px;
        width: 100%;
    }

    .contact--input-text-md {
        display: flex;
        color: var(--black);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        padding: 10px;
        border-radius: 10px;
        background: #f5fcff;
        width: 100%;
    }

    .contact--form--btn {
        display: flex;
        font-size: 16px;
        margin-top: 20px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        border-radius: 10px;
        background: var(--primary);
        color: var(--white);
        width: 100%;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    @media only screen and (max-width: 1800px) {
        .hero--section--title {
            font-size: 68px;
            line-height: 70px;
        }

        .skills--section--title {
            font-size: 28px;
            line-height: 40px;
        }
                  .favorites-heading--first {
                      
                      padding: 85.33px;
                  }
    }

    @media only screen and (max-width: 1600px) {
        .skills--section--heading {
            font-size: 54px;
            line-height: 70px;
        }

        .skills--section--container {
            gap: 16px;
        }

        .skills--section--card {
            gap: 28px;
        }

        .skills--section--card--content {
            gap: 20px;
        }

        .skills--section--title {
            font-size: 23px;
            line-height: 30px;
        }

        .skills--section--description {
            font-size: 17.333px;
            line-height: 27px;
        }
        
    }

    @media only screen and (max-width: 1200px) {
        .btn-outline-primary {
            display: none;
        }

        .hero--section {
            display: flex;
            flex-direction: column-reverse;
            padding-bottom: 70px;
        }

        .hero--section--title,
        .hero--section-description,
        .footer--content {
            text-align: center;
        }

        .skills--section--container,
        .about--section,
        .footer--link--container,
        .footer--items>ul,
        .footer--content--container {
            display: flex;
            flex-direction: column;
        }

        .skills--section,
        .hero--section--content,
        .hero--section--content--box {
            align-items: center;
        }

        .skills--section,
        .footer--container {
            gap: 20px;
            padding-top: 70px;
            padding-bottom: 70px;
        }

        .about--section {
            gap: 20px;
            padding-top: 0;
            padding-bottom: 0;
        }


        .hero--section--title,
        .skills--section--heading,
        .sections--heading {
            font-size: 35px;
            line-height: 40px;
        }

        .hero--section--content--box {
            gap: 10px;
        }

        .container {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .footer--social--icon>ul,
        .footer--items>ul {
            padding: 0;
            align-items: center;
        }
    

        .divider {
            margin: 20px;
        }
    }

    .nav__hamburger {
        display: none;
        width: 1.875rem;
        height: 1.313rem;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top: 25px;
        right: 25px;
    }

    .nav__hamburger__line {
        display: block;
        height: 0.188rem;
        width: 100%;
        background-color: #000000;
        border-radius: 0.625rem;
        transition: all ease-in-out 0.2s;
    }

    @media screen and (max-width: 1200px) {
        .nav__hamburger {
            display: flex;
            z-index: 200;
        }

        .navbar--items {
            display: flex;
        }

        .navbar--items {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255);
            top: -20rem;
            left: 0;
            width: 100%;
            transition: all ease-in-out 0.4s;
        }

        .navbar--items ul {
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 3rem 0 0.6rem;
            z-index: -100;
        }

        .navbar--items ul li {
            text-align: center;
        }

        .navbar--items ul li a {
            padding: 0.5rem;
        }

        .navbar--items {
            z-index: -1000;
        }

        .navbar--items.active {
            top: 30px;
        }

        .nav__hamburger.active :nth-child(1) {
            transform: rotate(45deg) translate(0.45rem, 0.1875rem);
        }

        .nav__hamburger.active :nth-child(2) {
            opacity: 0;
        }

        .nav__hamburger.active :nth-child(3) {
            transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
        }
            .favorites {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
        
            .favorites-heading {
                font-size: 24px;
                text-align: center;
                
            }
          
            .favorites>img {
                max-width: 100%;
                height: auto;
                margin-left: 0;
                justify-content: center;
            }
                    .contact--section,
                    .contact--me--small {
                        padding: 60px 20px;
                    }
    }
    @media only screen and (max-width: 992px) {

        .navbar {
            padding: 15px 20px;
        }

        .hero--section {
            padding: 80px 20px;
            grid-template-columns: 1fr;
            gap: 20px;
        }

        .hero--section--title {
            font-size: 48px;
            line-height: 58px;
        }
    
        .btn {
            padding: 12px 24px;
            font-size: 14px;
        }

        .university {
            gap: 20px;
        }

        .university-heading {
            font-size: 24px;
        }
        .university-heading--first {
                font-size: 24px;
        }

        .skills--section {
            padding: 80px 20px;
        }

        .skills--section--heading {
            font-size: 36px;
            line-height: 40px;
        }

        .skills--section--container {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }

        .skills--section--title {
            font-size: 28px;
            line-height: 35px;
        }

        .skills--section--description {
            font-size: 16px;
            line-height: 24px;
        }

        .about--section {
            padding: 80px 20px;
            gap: 40px;
            grid-template-columns: 1fr;
        }

        .about--section--heading {
            font-size: 36px;
        }

        .about--section--alt {
            gap: 16px;
        }
            .about--section--alt>h1 {
                font-size: 36x;
            }

        .footer--container {
            padding: 50px 20px;
        }

        .footer--content {
            font-size: 16px;
            line-height: 24px;
        }

        .footer--social--icon>ul {
        justify-content: space-evenly;
        width: 100%;
            max-width: 100%;
        }
        .contact--section {
            padding: 80px 20px; 
        }
        
        .contact--me--small {
                padding: 20px;
        }
    }

    @media only screen and (max-width: 768px) {

        .navbar {
            padding: 15px 10px;
        }

        .hero--section {
            padding: 0px 0px;
        }

        .hero--section--title {
            font-size: 36px;
            line-height: 42px;
        }

        .btn {
            padding: 10px 20px;
            font-size: 14px;
            
            
        }
        .hero--section--content--box>button {
            margin-top: 10px;
            display: block;
                margin: 10px auto;
                text-align: center;
        }

        .university-heading {
            font-size: 20px;
        }

        .skills--section {
            padding: 60px 10px;
            align-items: center;
        }

        .skills--section--heading {
            font-size: 24px;
            line-height: 32px;
            text-align: center;
        }

        .skills--section--container {
            grid-template-columns: 1fr;
            flex-direction: row;
            flex-wrap: wrap;
            
        }

        .skills--section--title {
            font-size: 22px;
            line-height: 28px;
        }

        .skills--section--description {
            font-size: 14px;
            line-height: 21px;
        }

        .about--section {
            padding: 60px 10px;
        }

        .about--section--heading {
            font-size: 24px;
        }

        .about--section--alt {
            gap: 10px;
        }
        .about--section--alt >h1 {
            font-size: 30px;
        }
        .contact--section {
            padding: 60px 10px;
        }
        
        .contact--me--small {
            padding: 10px;
        }
        
        .contact--label,.contact--input-text-md {
            width: 100%;
        }

    .footer--container {
        padding: 15px 10px;
    }
    
    .footer--social--icon>ul {
            justify-content: space-evenly;
            margin-left: 5px;
    }

    .divider {
        margin: 20px 0;
    }
    .favorites-heading--first {
        font-size: 24px;
            line-height: 32px;
            text-align: center;
            padding-bottom: 0%;
            padding-top: 0%;
    }
         .favorites >img {
            flex-direction: column;
            align-items: flex-start;
         }
    .contact--section,
    .contact--me--small {
        padding: 60px 10px;
    }

    .contact--label,
    .contact--input-text-md,
    .contact--form--btn {
        width: 100%;
    }
    
    }